<template>
    <div class="wrapper pay-wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="2" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container mobile-bread">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left mobile-bread">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb class="flex x-left">
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{ path: '/video-list' }">视频回放</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item style="max-width:960px;" class="row-1">视频购买</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
            </div>
            <!-- 支付信息 -->
            <div class="video-pay container">
                <div class="flex video-pay-user">
                    <!-- 视频缩略图 -->
                    <div class="video-info">
                        <div class="video-image">
                            <img src="https://img.videocc.net/uimage/4/4392459050/8/4392459050e089b34fb6758c05034368_0.jpg" alt="">
                        </div>
                    </div>
                    <!-- 作者信息 -->
                    <div class="right-info flex-column x-between">
                        <div class="video-title">外科患者营养支持研讨会—普通外科手术患者</div>
                        <div class="author-info pl20 pr20 flex y-center">
                            <div class="flex y-center" style="cursor: pointer;" @click="expertJumpDetail(videoDetailList.user_id)">
                                <img class="img" :src="videoDetailList.avatar | urlFilter(250)" alt="">
                                <div class="flex-column ml15" style="max-width:180px;">
                                    <p class="t-l row-1">
                                        <span class="strong fs16 color333">{{ videoDetailList.real_name }}</span>
                                        <span class="ml10 color999 fs12">{{ videoDetailList.department }}</span>
                                    </p>
                                    <p class="t-l mt5 color666 fs12 row-1">{{ videoDetailList.org_cnname }}</p>
                                </div>
                            </div> 
                            <div class="attention-btn flex x-center y-center cursor" @click="getFollowExperts(videoDetailList.user_id,true)">
                                <i class="med med-jiahao"></i>
                                <span class="fs12 strong colorfff ml5">关注</span>
                            </div>
                        </div>
                        <div class="price flex x-left y-center">
                            <div class="price-num">￥899.00</div>
                            <div class="ml10 color999 fs14 date">课程有效期至2023-10-23</div>
                        </div>
                    </div>
                </div>
                <!-- 平台优惠 -->
                <div class="select-item flex x-left y-center mt40">
                    <div class="item-label">平台优惠：</div>
                    <a-select default-value="lucy" style="max-width: 450px">
                        <a-select-option value="lucy">
                            Lucy
                        </a-select-option>
                    </a-select>
                </div>
                <!-- 支付方式 -->
                <div class="pay-select flex x-left y-center mt40">
                    <div class="item-label">支付方式：</div>
                    <div class="item-content flex x-left y-center ">
                        <div class="pay-item flex x-center y-center">
                            <span class="med med-zhifubaozhifu"></span>
                            <span class="color333 font14">支付宝支付</span>
                        </div>
                        <div class="pay-item flex x-center y-center ml20">
                            <span class="med med-weixinzhifu"></span>
                            <span class="color333 font14">微信支付</span>
                        </div>
                        <div class="pay-item flex x-center y-center ml20">
                            <span class="med med-daizhifu"></span>
                            <span class="color333 font14">网银支付</span>
                        </div>
                        <div class="pay-item flex x-center y-center ml20">
                            <span class="med med-weixinzhifu"></span>
                            <span class="color333 font14">M豆支付</span>
                        </div>
                    </div>
                </div>
                <!-- 提示 -->
                <div class="tips font14 t-l mt50">
                    <span class="color666">注意：</span>
                    <span>此视频一旦购买，无法退费，请您仔细确认</span>
                </div>
                <!-- check -->
                <div class="check color999 t-l mt10 flex y-center">
                    <a-radio>我已了解，确认购买</a-radio>
                </div>
                <!-- 支付金额 -->
                <div class="pay-price t-l mt40">
                    <div class="title color333 font16">需支付金额</div>
                    <div class="course">
                        <span>课程价格：</span>
                        <span>￥56.80</span>
                    </div>
                    <div class="platform">
                        <span>平台价格：</span>
                        <span>-￥56.80</span>
                    </div>
                    <div class="deal">
                        <span>应付金额：</span>
                        <span>￥50.80</span>
                    </div>
                </div>
                <!-- 提交按钮 -->
                <div class="submit">
                    <div class="submit-btn">确认支付</div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import { videoLogo, mediaNavList } from '@/config/const';
export default {
    components: {
        Header,
        Footer
    },
    name: "pay",
    data () {
        return {
            navList: mediaNavList,//顶部菜单
            logoUrl: videoLogo,
            videoDetailList: {
                real_name: '于凯江',
                department: '重症医学科',
                org_cnname: '哈尔滨医科大学附属第一医院',
                avatr: 'https://files.sciconf.cn/user/2021/11/20211115/2021111514555010678349251.png!wx250'
            }
        }
    }
}
</script>

<style scoped lang="less">
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
    .video-pay {
        padding: 20px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        .video-info {
            width: 460px;
            .video-image {
                width: 100%;
                padding-bottom: 56.25%;
                position: relative;
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .right-info {
            width: 58%;
            .video-title {
                font-size: 20px;
                font-weight: bold;
                text-align: left;
            }
            .author-info {
                width: 398px;
                height: 100px;
                background: #ffffff;
                box-shadow: 0px 0px 4px 0px rgba(30, 81, 201, 0.15);
                border-radius: 10px;
                margin-top: 20px;
                position: relative;
                .img {
                    width: 66px;
                    height: 66px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: top;
                }
                .attention-btn {
                    width: 64px;
                    height: 34px;
                    background: linear-gradient(to right, #5f96e6, #1e51c9);
                    border-radius: 5px;
                    cursor: pointer;
                    .med-jiahao::before {
                        font-size: 10px;
                        color: #ffffff;
                    }
                }
                
            }
            .price {
                margin-top: 50px;
                .price-num {
                    font-size: 28px;
                    font-weight: bold;
                    color: #FFA45D;
                }
                .date {
                    margin-top: 5px;
                }
            }
        }
        .select-item {
            width: 100%;
            .item-label {
                width: 80px;
                text-align: left;
                
            }
            /deep/.ant-select {
                flex: 1;
            }
        }
        .pay-select {
            width: 100%;
            min-width: 550px;
            .item-label {
                width: 80px;
                text-align: left;
            }
            .item-content {
                flex: 1;
                & .pay-item:nth-child(1) {
                    & span:nth-child(1) {
                        color: #00A0EA;
                    }
                }
                & .pay-item:nth-child(2) {
                    & span:nth-child(1) {
                        color: #43C93E;
                    }
                }
                & .pay-item:nth-child(3) {
                    & span:nth-child(1) {
                        color: #FFA937;
                    }
                }
                & .pay-item:nth-child(4) {
                    & span:nth-child(1) {
                        color: #43C93E;
                    }
                }
                .pay-item {
                    cursor: pointer;
                    background: #F9F9F9;
                    height: 44px;
                    padding: 0 20px;
                    & span:nth-child(2) {
                        margin-left: 10px;
                    }
                    & span:nth-child(1) {
                        font-size: 30px;
                    }
                }
            }
        }
        .tips {
            & span:nth-child(2) {
                color: #C80909;
            }
        }
        .submit {
            .submit-btn {
                background: linear-gradient(to right, #5f96e6, #1e51c9);
                border-radius: 0.13333rem;
                cursor: pointer;
                display: inline-block;
                width: 219px;
                height: 50px;
                line-height: 50px;
                color: #FFFFFF;
                text-align: center;
                font-size: 14px;
            }
        }
        .pay-price {
            >div {
                margin-bottom: 15px;
                & span:nth-child(2) {
                    font-size: 22px;
                }
            }
            .course {
                & span:nth-child(2) {
                    color: #000000;
                }
            }
            .platform {
                & span:nth-child(2) {
                    color: #43C93E;
                }
            }
            .deal {
                & span:nth-child(2) {
                    color: #FFA45D;
                }
            }
            .title {
                font-size: 16px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .video-pay {
        padding: 0 10px 10px 10px !important;
        .video-pay-user {
            display: block;
        }
        .video-info {
            width: calc(100% + 20px) !important;
            margin-left: -10px;
        }
        .right-info {
            .video-title {
                margin-top: 20px;
            }
            width: 100% !important;
            .author-info {
                width: 100% !important;
            }
            .price {
                margin-top: 20px !important;
            }
        }
        .select-item  {
            display: block;
            /deep/.ant-select {
                width: 100% !important;
                margin-top: 20px;
            }
        }
        .pay-select {
            display: block;
            min-width: 100% !important;
            .item-content {
                flex-wrap: wrap;
                justify-content: space-between;
                .pay-item {
                    width: 48%;
                    margin: 20px 0 0 0;
                }
            }
        }
    }
    .mobile-bread {
        display: none;
    }
}
</style>
